export function removeSpacesBetweenNumbers(value: string) {
  return +value.replace(/\s/g, '');
}

export function numberWithSpacesToString(
  number: number | string,
  separator: string,
) {
  return (
    number &&
    number
      .toString()
      .match(/^-?\d+(?:\.\d{0,2})?/)?.[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  );
}

export function ceilPriceToHundredThousand(number: number) {
  return Math.ceil(number / 100000) * 100000;
}

export function ceilMileageToFiftyThousand(number: number) {
  return Math.ceil(number / 50000) * 50000;
}

export function floorMileageToFiftyThousand(number: number) {
  return Math.floor(number / 50000) * 50000;
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return;

  const cuttedPhoneNumber = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/);

  if (!cuttedPhoneNumber) return phoneNumber;

  return (
    cuttedPhoneNumber[1] +
    ' ' +
    cuttedPhoneNumber[2] +
    ' ' +
    cuttedPhoneNumber[3]
  );
};

export const formatUrlPath = (url: string) => {
  return `/${url.replace(/\s/g, '%20').toLocaleLowerCase()}`;
};

export const formatBodyUrlPath = (url: string) => {
  return `${url.replace(/[\s/]/g, '-').toLocaleLowerCase()}`;
};

export const formatUrlPathNew = (url: string) => {
  return url ? `/${url.toLowerCase()}` : '';
};

export const capitalizeFirstLetter = (string: string): string => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatDealerPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  return phoneNumber.startsWith('0') ? phoneNumber.substring(1) : phoneNumber;
};
