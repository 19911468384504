'use client';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/layout';
import React, { RefObject, useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { ReactComponent as ArrowBackwardCircle } from '@/app/_icons/ArrowBackwardCircleOutline.svg';
import { ReactComponent as ArrowForwardCircle } from '@/app/_icons/ArrowForwardCircleOutline.svg';
import NewsFeedCard from '@/app/dealerships/[id]/_components/news-feed-card/NewsFeedCard';
import { BlogListing } from '@/services/blog/BlogService';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import styles from './NewsFeed.module.scss';

const NewsFeed = ({ dealerBlogs }: { dealerBlogs: BlogListing[] }) => {
  const swiperRef: RefObject<SwiperRef> = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const CustomNavigation = () => {
    return (
      <div className={styles.newsFeedNavigation}>
        <button onClick={goPrev} className={styles.newsFeedSwiperPrev}>
          <ArrowBackwardCircle />
        </button>
        <button onClick={goNext} className={styles.newsFeedSwiperNext}>
          <ArrowForwardCircle />
        </button>
      </div>
    );
  };

  return (
    <>
      <Box
        className={styles.newsFeedWrapper}
        paddingY={{ base: 14, lg: '100px' }}
      >
        <Container
          maxW={1280}
          width={'100%'}
          className={styles.newsFeedHeader}
          marginBottom={{ base: 16, lg: '9px', md: 10 }}
          paddingX={{ base: 6, md: '56px' }}
        >
          <Flex
            alignItems={'center'}
            direction={{ base: 'column', md: 'row' }}
            flexWrap={'wrap'}
          >
            <Box
              className={styles.headingElement}
              mb={'8!important'}
              px={'0!important'}
            >
              <Text
                fontSize="xs"
                textTransform={'uppercase'}
                marginBottom={4}
                color={'white'}
              >
                Read our latest articles for tips and advice
              </Text>
              <Heading as="h2" className={styles.titleLg} color={'white'}>
                News Feed
              </Heading>
            </Box>
            <Box marginLeft={{ md: 'auto' }}>
              <LinkCustom
                role="link"
                className="btnOutlineWhite"
                href="/blog/latest-news"
              >
                View all
                <ChevronRightIcon width={4} height={4} marginLeft={3} />
              </LinkCustom>
            </Box>
          </Flex>
        </Container>
        <Box
          maxWidth={1280}
          width={'100%'}
          marginX={'auto'}
          paddingX={{ base: 0, sm: '6', md: '56px' }}
        >
          <Swiper
            className={styles.newsFeedSlider}
            ref={swiperRef}
            slidesPerView={3}
            spaceBetween={40}
            modules={[Pagination, Navigation]}
            pagination={{
              el: styles.newsFeedNavigation,
              type: 'fraction',
              formatFractionCurrent: function (number) {
                return number < 10 ? '0' + number : number.toString();
              },
            }}
            navigation={{
              nextEl: styles.newsFeedSwiperNext,
              prevEl: styles.newsFeedSwiperPrev,
            }}
            onSlideChange={(swiper) => {
              setCurrentPage(swiper.realIndex + 1);
            }}
            onSwiper={(swiper) => {
              setTotalPages(swiper.slides.length);
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 15,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              640: {
                slidesPerView: 1,
              },
              641: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {dealerBlogs?.map((blog, i) => (
              <SwiperSlide key={i}>
                <NewsFeedCard {...blog} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        <Box
          className={styles.newsFeedSwiperControls}
          paddingX={{ base: 6, md: '56px' }}
        >
          <Box className={styles.newsFeedPagination}>
            <span className="current-page">{currentPage}</span>
            <span className="separator">/</span>
            <span className="total-pages">{totalPages}</span>
          </Box>
          <CustomNavigation />
        </Box>
      </Box>
    </>
  );
};

export default NewsFeed;
