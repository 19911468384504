'use client';
import Link from 'next/link';
import React from 'react';

import { ReactComponent as ArroRightBlack } from '@/app/_icons/Ck_arrow_right_black16px.svg';

import Styles from './AwardAndPeerview.module.scss';

const ReadReviewsLink = ({
  reviewCount,
  onClick,
}: {
  reviewCount: number;
  onClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <Link className={Styles.themeLink} href={'#'} onClick={onClick}>
      Read {reviewCount} reviews
      <ArroRightBlack className={Styles.menuIcon} />
    </Link>
  );
};

export default ReadReviewsLink;
