'use client';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, Text } from '@chakra-ui/layout';
import cn from 'classnames';
import Link from 'next/link';
import React, { RefObject, useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react';

import LinkCustom from '@/app/_components/link/LinkCustom';
import PromotionsCard from '@/app/_components/promotions-card/PromotionsCard';
import { ReactComponent as ArrowBackwardCircle } from '@/app/_icons/ArrowBackwardCircleOutline.svg';
import { ReactComponent as ArrowForwardCircle } from '@/app/_icons/ArrowForwardCircleOutline.svg';
import { getImageLink } from '@/services/link.service';
import type { Special } from '@/types/specials/specials.types';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules

import styles from './Promotions.module.scss';

type Props = {
  isDealershipPage?: boolean;
  featuredItems: Special[];
  dealershipName?: string;
};

const Promotions = ({
  dealershipName,
  featuredItems,
  isDealershipPage = false,
}: Props) => {
  const swiperRef: RefObject<SwiperRef> = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const CustomNavigation = () => {
    return (
      <div className={styles.promotionSwiperNavigation}>
        <button
          onClick={goPrev}
          className={styles.promotionSwiperButtonPrev}
          role="button"
          name="Prev"
        >
          <ArrowBackwardCircle />
        </button>
        <button
          onClick={goNext}
          className={styles.promotionSwiperButtonNext}
          role="button"
          name="Next"
        >
          <ArrowForwardCircle />
        </button>
      </div>
    );
  };

  return (
    <>
      <Box
        className={styles.promotionsWrapper}
        paddingX={{ base: 6, md: '56px' }}
        paddingTop={{ base: 16, lg: '100px' }}
        paddingBottom={{ base: 10, lg: '100px' }}
      >
        <Container
          maxW={1280}
          width={'100%'}
          className={styles.dealershipHeader}
          marginBottom={{ base: 16, lg: '9px', md: 10 }}
          paddingX={0}
        >
          <Flex
            alignItems={'center'}
            direction={{ base: 'column', md: 'row' }}
            flexWrap={'wrap'}
          >
            <Box
              className={styles.headingElement}
              mb={'8!important'}
              px={'0!important'}
            >
              <Text fontSize="xs" textTransform={'uppercase'} marginBottom={4}>
                {`View the latest promotions at ${dealershipName}`}
              </Text>
              <Link
                className={styles.latestPromotionsHeading}
                color={'white'}
                href="/promotions"
              >
                Latest Promotions
              </Link>
            </Box>
            <Box marginLeft={{ md: 'auto' }}>
              <LinkCustom
                role="link"
                className="btnOutlineWhite"
                href="/promotions"
              >
                View all
                <ChevronRightIcon width={4} height={4} marginLeft={3} />
              </LinkCustom>
            </Box>
          </Flex>
        </Container>
        <Swiper
          ref={swiperRef}
          slidesPerView={3}
          spaceBetween={40}
          modules={[Pagination, Navigation]}
          pagination={{
            el: styles.promotionSwiperPagination,
            type: 'fraction',
            formatFractionCurrent: function (number) {
              return number < 10 ? '0' + number : number.toString();
            },
          }}
          navigation={{
            nextEl: styles.promotionSwiperButtonNext,
            prevEl: styles.promotionSwiperButtonPrev,
          }}
          className={styles.featureSlider}
          onSlideChange={(swiper) => {
            setCurrentPage(swiper.realIndex + 1);
          }}
          onSwiper={(swiper) => {
            setTotalPages(swiper.slides.length);
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            576: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            641: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
        >
          {featuredItems &&
            featuredItems?.map((item, index) => (
              <SwiperSlide key={index}>
                <PromotionsCard
                  item={{
                    title: item?.title,
                    sub_title: item?.sub_title,
                    monthly_repayment: item?.monthly_repayment,
                    cashback: item?.cashback,
                    payment_terms: item?.payment_terms,
                    deposit: item?.deposit,
                    residual: item?.residual,
                    interest_rate: item?.interest_rate,
                    id: item?.id,
                    image: getImageLink(item?.image || '') ?? '',
                    special_for: item.special_for,
                    vehicle_type: item.vehicle_type,
                  }}
                  key={item.id}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div
          className={cn(
            styles.featureSwiperControls,
            isDealershipPage && styles.showNavigation,
          )}
        >
          <div className={styles.promotionSwiperPagination}>
            <span className="current-page">{currentPage}</span>
            <span className="separator">/</span>
            <span className="total-pages">{totalPages}</span>
          </div>
          <CustomNavigation />
        </div>
      </Box>
    </>
  );
};

export default Promotions;
