import { Box, Heading } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// eslint-disable-next-line import/order
import { ReactComponent as ArrowRightBlack } from '@/app/_icons/back-arrow-white.svg';

import 'swiper/css';
import 'swiper/css/pagination';
import './ServiceSection.scss';
import { getImageLink } from '@/services/link.service';
import { CallToAction } from '@/types/home/home.types';
import { directusImageLoader } from '@/utils/loaders';

const ServiceSection = ({
  header_call_to_actions,
  name,
}: {
  header_call_to_actions: { call_to_actions_id: CallToAction }[];
  name: string;
}) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '">' + '</span>';
    },
  };

  return (
    <Box className="serviceWrapper" paddingTop={14} paddingBottom={14}>
      <Box paddingX={6}>
        <Heading
          as="h1"
          className="titleLg"
          color={'white'}
          textAlign={'center'}
          fontFamily={'KiaSignature'}
          fontWeight={600}
          fontStyle={'normal'}
        >
          {name}
        </Heading>
      </Box>

      <Swiper
        className="serviceSwiper"
        pagination={pagination}
        modules={[Pagination]}
        slidesPerView={4}
        spaceBetween={40}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
          },
          844: {
            slidesPerView: 3,
          },
          1120: {
            slidesPerView: 4,
          },
        }}
      >
        {header_call_to_actions?.map(
          ({ call_to_actions_id }: { call_to_actions_id: CallToAction }, i) => (
            <SwiperSlide key={i}>
              <Box className="toolsCard" textAlign={'center'}>
                <Image
                  src={getImageLink(call_to_actions_id?.icon) || ''}
                  loader={directusImageLoader}
                  alt="cta"
                  className="toolIcon"
                  width={90}
                  height={90}
                />
                <Link className="themeLink" href={call_to_actions_id?.url}>
                  {call_to_actions_id?.text}
                  <ArrowRightBlack className="menuIcon" />
                </Link>
                <Box className="toolDescription">
                  {call_to_actions_id?.heading}
                </Box>
              </Box>
            </SwiperSlide>
          ),
        )}
      </Swiper>
    </Box>
  );
};

export default ServiceSection;
