'use client';

import { Box, Container, Flex, Heading, Text } from '@chakra-ui/layout';
import { getImageProps } from 'next/image';
import Link from 'next/link';
import React from 'react';

import Peerview from '@/app/_components/peerview/Peerview';
import { ReactComponent as ArroRightBlack } from '@/app/_icons/Ck_arrow_right_black16px.svg';
import { getImageLink } from '@/services/link.service';
import { Award, AwardWrapper } from '@/types/home/home.types';
import { PeerviewIdentifier } from '@/types/peerview/peerview.types';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './AwardAndPeerview.module.scss';
import ReadReviewsLink from './ReadReviewsLink';

type Props = {
  heading?: string;
  bgColor?: string;
  awards?: AwardWrapper[];
  peerview?: PeerviewIdentifier;
};

const AwardAndPeerview = ({
  heading = 'See what our satisfied customers say',
  bgColor = '#ffffff',
  awards,
  peerview,
}: Props) => {
  const imageKeys = { desktopKey: 'image-100-100', mobileKey: 'image-100-100' };

  const hasPeerview = peerview && peerview.type && peerview.itemId;

  const getImageSrc = (award: Award): string => {
    if (typeof award.image === 'string') {
      return award.image;
    }
    return award.image?.id;
  };

  return (
    <Box backgroundColor={bgColor}>
      <Container
        maxWidth={{ base: '100%', md: '1328px' }}
        alignContent={'center'}
        paddingX={{ base: 18, lg: 0 }}
        paddingTop={{ base: 20, lg: 140 }}
        paddingBottom={{ base: 20, md: 4, lg: 75 }}
        className={Styles.awardAndPeerviewWrapper}
      >
        <Box
          className={Styles.headingElement}
          marginBottom={{ base: 20, md: 18, lg: 20 }}
        >
          <Heading as="h3" className={Styles.titleLg} textAlign={'center'}>
            {heading}
          </Heading>
        </Box>
        <Flex
          direction={['column', 'row']}
          flexWrap={'wrap'}
          justifyContent={'center'}
          gap={{ base: '20', md: '4' }}
        >
          {awards?.map((awardWrapper, i) => {
            const award = awardWrapper.awards_id;
            const imageSrc = getImageSrc(award);
            const common = {
              alt: 'Hero',
              width: 100,
              height: 100,
              loader: directusImageLoader,
            };
            const {
              props: { srcSet: mobile },
            } = getImageProps({
              ...common,
              src: `${getImageLink(imageSrc)}?key=${imageKeys.mobileKey}`,
            });
            const {
              props: { srcSet: desktop, ...rest },
            } = getImageProps({
              ...common,
              src: `${getImageLink(imageSrc)}?key=${imageKeys.desktopKey}`,
            });

            return (
              <Box
                key={i}
                className={Styles.awardItem}
                textAlign={'center'}
                paddingY={['0px', '0px', '65px', '65px']}
              >
                <picture className={Styles.awardImg}>
                  <source media="(max-width: 575px)" srcSet={mobile} />
                  <source media="(min-width: 576px)" srcSet={desktop} />
                  <img {...rest} alt="hero-banner-image" />
                </picture>

                <Box className={Styles.headingElement}>
                  <Heading
                    as="h5"
                    className={Styles.titleSm}
                    textAlign={'center'}
                    marginBottom={2}
                  >
                    {award.text}
                  </Heading>
                </Box>
                <Text className={Styles.titleText} marginBottom={4}>
                  {award.description}
                </Text>
                {award.url && (
                  <Link className={Styles.themeLink} href={award.url ?? ''}>
                    Read more
                    <ArroRightBlack className={Styles.menuIcon} />
                  </Link>
                )}
              </Box>
            );
          })}

          {hasPeerview && (
            <Box
              className={Styles.awardItem}
              textAlign={'center'}
              paddingY={['0px', '0px', '65px', '65px']}
            >
              <Box className={Styles.peerViewElement} mb={5}>
                <Peerview
                  type={peerview.type as string}
                  itemIdentifier={peerview.itemId as string}
                  text="Too many good choices? Have a look at what our satisfied customers are saying"
                  canViewReviews={true}
                  LinkComponent={ReadReviewsLink}
                >
                  <Text
                    className={Styles.titleText}
                    marginBottom={4}
                    marginTop={4}
                  >
                    Too many good choices? Have a look at what our satisfied
                    customers are saying
                  </Text>
                </Peerview>
              </Box>
            </Box>
          )}
        </Flex>
      </Container>
    </Box>
  );
};

export default AwardAndPeerview;
