'use client';
import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/layout';
import { getImageProps } from 'next/image';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { getImageLink } from '@/services/link.service';
import { CallToAction } from '@/types/home/home.types';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './TradeIn.module.scss';

const TradeIn = ({
  trade_in_call_to_action,
}: {
  trade_in_call_to_action: CallToAction;
}) => {
  const imageKeys = { desktopKey: 'image-693-377', mobileKey: 'image-360-170' };
  const common = {
    alt: 'Hero',
    width: 693,
    height: 377,
    loader: directusImageLoader,
  };
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: getImageLink(trade_in_call_to_action?.image)
      ? `${getImageLink(trade_in_call_to_action?.image)}?key=${imageKeys.mobileKey}`
      : '',
  });

  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    src: getImageLink(trade_in_call_to_action?.image)
      ? `${getImageLink(trade_in_call_to_action?.image)}?key=${imageKeys.desktopKey}`
      : '',
  });

  return (
    <section className={Styles.tradeInSection}>
      <Container
        maxWidth={{ base: '100%', md: '1160px' }}
        alignContent={'center'}
        paddingX={{ base: '6', '2xl': '0' }}
        paddingY={'14'}
      >
        <SimpleGrid
          gridTemplateColumns={{ md: '367px 1fr', lg: '467px 1fr' }}
          columns={{ base: 1, md: 2 }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          gap={{ base: 8 }}
        >
          <Box order={{ base: 1, md: 2 }}>
            <picture className={Styles.tradeInCarImg}>
              <source media="(max-width: 575px)" srcSet={mobile} />
              <source media="(min-width: 576px)" srcSet={desktop} />
              <img {...rest} alt="trad-in-image" />
            </picture>
          </Box>
          <Box className={Styles.headingElement} order={{ base: 2, md: 1 }}>
            <Heading
              as="h3"
              className={Styles.titleMd}
              marginBottom={{ base: 4, md: 5 }}
            >
              {trade_in_call_to_action?.heading}
            </Heading>
            <Text className={Styles.titleText}>
              {trade_in_call_to_action?.subheading}
            </Text>
            <LinkCustom
              role="link"
              className="btnOutline"
              href={trade_in_call_to_action?.url}
            >
              {trade_in_call_to_action?.text}
            </LinkCustom>
          </Box>
        </SimpleGrid>
      </Container>
    </section>
  );
};

export default TradeIn;
