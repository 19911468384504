import { SVG } from "@svgdotjs/svg.js";

import { PEERVIEW_BLUE } from "./constants";
import { getSplitNumber } from "./utils";


const RatingStars = ({ rating }: { rating: number }) => {
  const svgCanvas = SVG();
  let score = getSplitNumber(rating);

  if (rating === Math.floor(rating)) {
    score = {
      ratingWhole: '' + rating,
      ratingRemainder: '0',
    };
  }
  const ratingWhole = parseInt(score.ratingWhole);
  const ratingRemainder = parseInt(score.ratingRemainder);
  let i;
  let hMoveSize = 0;

  //For drawing whole stars example: draws 3 stars for 3.4
  for (i = 0; i < ratingWhole; i++) {
    //draw the circle
    const wholeCircle = svgCanvas.circle(30).fill(PEERVIEW_BLUE);

    //draw the star
    const wholeStar = svgCanvas
      .polygon('9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78')
      .fill('#FFF')
      .move(5, 4);

    //Move it into position
    if (i > 0) {
      wholeCircle.move(hMoveSize, 0);
      wholeStar.move(hMoveSize + 5, 4);
    }
    hMoveSize += 32;
  }

  //What percentage of the star needs to be masked
  let clipPerc = 0;

  if (!isNaN(ratingRemainder)) {
    clipPerc =
      (ratingRemainder > 10
        ? ratingRemainder / 10 / 10
        : ratingRemainder / 10) * 30;
  }
  const clipRect = svgCanvas
    .rect(30, 30)
    .fill(PEERVIEW_BLUE)
    .move(hMoveSize + clipPerc, 0);

  //Draw the remainder star and circle
  svgCanvas.circle(30).fill(PEERVIEW_BLUE).move(hMoveSize, 0);
  svgCanvas.circle(30).fill('#b0e3e8').move(hMoveSize, 0).clipWith(clipRect);

  //draw the star
  svgCanvas
    .polygon('9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78')
    .fill('#FFF')
    .move(hMoveSize + 5, 4);

  hMoveSize += 32;

  //Draws the remainder of the stars as masked. Example 4th and 5th star is masked in 3.4 rating
  for (i = 0; i < 5 - ratingWhole - 1; i++) {
    svgCanvas.circle(30).fill(PEERVIEW_BLUE).move(hMoveSize, 0);
    svgCanvas.circle(30).fill('#b0e3e8').move(hMoveSize, 0);
    svgCanvas
      .polygon('9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78')
      .fill('#FFF')
      .move(hMoveSize + 5, 4);
    hMoveSize += 32;
  }
  svgCanvas.viewbox(0, 0, 158, 30);
  const svgString = svgCanvas.svg();

  svgCanvas.clear();
  return <span dangerouslySetInnerHTML={{ __html: svgString }}></span>;
};

export default RatingStars;
