'use client';
import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/layout';
import { getImageProps } from 'next/image';

import LinkCustom from '@/app/_components/link/LinkCustom';
import { getImageLink } from '@/services/link.service';
import { CallToAction } from '@/types/home/home.types';
import { directusImageLoader } from '@/utils/loaders';

import Styles from './PreOwnedSelection.module.scss';

const PreOwnedSelection = ({
  pre_owned_call_to_action,
}: {
  pre_owned_call_to_action: CallToAction;
}) => {
  const imageKeys = { desktopKey: 'image-580-406', mobileKey: 'image-360-300' };
  const common = { alt: 'Hero', width: 580, height: 406, loader: directusImageLoader };
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    src: getImageLink(pre_owned_call_to_action?.image)
      ? `${getImageLink(pre_owned_call_to_action?.image)}?key=${imageKeys.mobileKey}`
      : '',
  });
  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    src: getImageLink(pre_owned_call_to_action?.image)
      ? `${getImageLink(pre_owned_call_to_action?.image)}?key=${imageKeys.desktopKey}`
      : '',
  });

  return (
    <section className={Styles.PreOwnedSelectionWrap}>
      <Container
        maxWidth={{ base: '100%', md: '1160px' }}
        alignContent={'center'}
        paddingX={{ base: '24px', lg: '0' }}
        paddingTop={'40px'}
        paddingBottom={{ base: '80px', md: '100px' }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          alignItems={{ base: 'flex-start', md: 'center' }}
        >
          <Box marginBottom={{ base: 8, md: 0 }}>
            <picture>
              <source media="(max-width: 575px)" srcSet={mobile} />
              <source media="(min-width: 576px)" srcSet={desktop} />
              <img {...rest} alt="pre-owned-image" />
            </picture>
          </Box>
          <Box
            className={Styles.headingElement}
            paddingLeft={{ base: 0, md: 14, lg: 20 }}
          >
            <Heading
              as="h3"
              className={Styles.titleLg}
              color={'white'}
              marginBottom={{ base: 4, md: 5 }}
            >
              {pre_owned_call_to_action?.heading}
            </Heading>
            <Text
              className={Styles.titleText}
              marginBottom={{ base: 5, sm: 8 }}
            >
              {pre_owned_call_to_action?.subheading}
            </Text>
            <LinkCustom
              className="btnWhite"
              href={pre_owned_call_to_action?.url}
            >
              {' '}
              {pre_owned_call_to_action?.text}
            </LinkCustom>
          </Box>
        </SimpleGrid>
      </Container>
    </section>
  );
};

export default PreOwnedSelection;
