import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/layout';

import { CustomerRating } from '@/types/peerview/peerview.types';

import { PEERVIEW_BLUE } from './constants';
import style from './Peerview.module.scss';
import RatingCircle from './RatingCircle';
import RatingText from './RatingText';
import { parseNumber, roundNumber } from './utils';

const CustomerRatingCard = ({ rating }: { rating: CustomerRating }) => {
  const parsedRating = parseNumber(rating.ratingScore);
  const ratingScoreStr = roundNumber(parsedRating, 1, 1).toString().split('');

  return (
    <Box className={style.customerRatingContainer} mb="25px" mt="25px">
      <Flex mb={'10px'}>
        <Box flex="1">
          <Box className={style.customerName}>{rating.displayName}</Box>
          <Box className={style.itemDescription}>{rating.ratingInfo}</Box>
        </Box>
        <Box className={style.ratingContainer}>
          <RatingCircle rating={rating.ratingScore} color={PEERVIEW_BLUE} />
          <RatingText rating={ratingScoreStr} />
        </Box>
      </Flex>
      <Box className={style.comments}>
        {rating.positiveNotes && rating.positiveNotes.length > 0 && (
          <Box mb="2px">
            <TriangleUpIcon color="green.500" /> {rating.positiveNotes}
          </Box>
        )}
        {rating.negativeNotes && rating.negativeNotes.length > 0 && (
          <Box mb="2px">
            <TriangleDownIcon color="red.500" /> {rating.negativeNotes}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomerRatingCard;
